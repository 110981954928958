import React, { memo } from "react";
import styles from "@aspen/theme/Home.module.less";
import {
  certifiedKycLevel,
  GA_EVENT_NAME,
  i18nUtil,
  reportEvent,
  TRADE_PATH,
  WALLET_PATHS,
  YIELD_PATHS,
  systemDetection,
  TRADE_PATH_DEFAULT_PATH,
  ADGM_TRADE_PATH_DEFAULT_PATH
} from "@aspen/libs";
import Image from "next/image";
import type { IKycLevel } from "@aspen/model";
import { BRAND } from "@aspen/model";
import clsx from "clsx";
import { useRouter } from "next/router";
import { EventEntranceView } from "./DynamicImport";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

const QuickEntranceView = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const system = systemDetection();
  const handleClick = (route: string, coinCode?: string) => {
    reportEvent({ moduleName: route });
    if (coinCode) {
      router.push({ pathname: `${TRADE_PATH}/${coinCode}` });
    } else {
      router.push({
        pathname: route
      });
    }
  };
  return (
    <div className={styles.quickEentrance}>
      <div
        className={styles.entrance}
        onClick={() => {
          reportEvent({ moduleName: GA_EVENT_NAME.home.depositaCrypto });
          if (props.showGuideModal(1)) {
            handleClick(WALLET_PATHS.WALLET_DEPOSIT);
          }
        }}>
        <span className={`${styles.quickIcon} ` + `pointerNone`}>
          <Image
            unoptimized
            src={require("@aspen/assets/images/ic_deposit@2x.png").default}
            alt=""
          />
        </span>
        {intl["home.deposit"]}
      </div>
      <div
        className={styles.entrance}
        onClick={() => {
          reportEvent({ moduleName: GA_EVENT_NAME.home.purchaseUSTD });
          router.push({
            pathname:
              system === BRAND ? `${TRADE_PATH}/USDC_USDT` : YIELD_PATHS.YIELD_CREATE_DCA_PLAN
          });
        }}>
        <span className={`${styles.quickIcon} ` + `pointerNone`}>
          <Image
            unoptimized
            src={require("@aspen/assets/images/ic_purchase@2x.png").default}
            alt=""
          />
        </span>
        {intl["home.deposit.fiat"]}
      </div>
      <div
        className={styles.entrance}
        onClick={() => {
          reportEvent({ moduleName: GA_EVENT_NAME.home.trade });
          handleClick(system === BRAND ? TRADE_PATH_DEFAULT_PATH : ADGM_TRADE_PATH_DEFAULT_PATH);
        }}>
        <span className={`${styles.quickIcon} ` + `pointerNone`}>
          <Image unoptimized src={require("@aspen/assets/images/ic_trade@2x.png").default} alt="" />
        </span>
        {intl["title.market"]}
      </div>
      <EventEntranceView
        {...props}
        handleClick={(route) => {
          handleClick(route);
        }}
      />
    </div>
  );
};
export default memo(QuickEntranceView);
