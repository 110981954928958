import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import styles from "@aspen/theme/Wallet.module.less";
import type { IAssetWalletInfo, IBankAccount, ICurrencyConfig, IKycLevel } from "@aspen/model";
import { COMMON_CACHE_PRIORTY_KEYS, ADGM_BRAND } from "@aspen/model";
import { preWithdraw } from "@aspen/services";
import {
  GA_EVENT_NAME,
  USER_AUTH,
  USER_ROLE_POWER,
  WALLET_PATHS,
  reportEvent,
  i18nUtil,
  systemDetection
} from "@aspen/libs";
import {
  WalletContext,
  useAppDispatch,
  useAppSelector,
  updateIsPreWithdraw,
  updateIsShowAmount,
  showAmount
} from "@aspen/store";
import { useCachePriorityData } from "@aspen/hooks";
import { WithKYCInfo } from "@aspen/ui";
import { Dashboard, WalletCategoryDetails, ModalBankAccountStatus } from "@aspen/widgets";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

function Wallet(props: IProps) {
  const intl = i18nUtil.t();
  const title: string = intl["wallet"];
  const deposit = intl["button.deposit.crypto.currency"];
  const depositFiat = intl["button.deposit.fiat"];
  const whitelist = intl["button.withdraw.white.list.address"];

  const [showModal, setShowModal] = useState<boolean>(false);
  const [circleInfo, setCircleInfo] = useState<IBankAccount>();
  const system = systemDetection();

  const router = useRouter();
  const dispatch = useAppDispatch();
  const isShowAmount = useAppSelector(showAmount);
  useEffect(() => {
    getInitData();
  }, []);

  // @ts-ignore
  const { mutate } = useCachePriorityData<IAssetWalletInfo>(COMMON_CACHE_PRIORTY_KEYS.walletInfo);

  useCachePriorityData<ICurrencyConfig[]>(COMMON_CACHE_PRIORTY_KEYS.depositCoinList);

  // 初始化数据,
  const getInitData: () => void = () => {
    if (
      typeof window != "undefined" &&
      USER_ROLE_POWER[localStorage.getItem(USER_AUTH) ?? ""]?.depositAndWithdraw
    ) {
      preWithdraw().then((res) => {
        if (res?.code == "0") {
          dispatch(updateIsPreWithdraw(res?.data));
        }
      });
    }
  };

  const getCircleInfo = (params) => {
    setCircleInfo(params);
  };

  const showCircleModal = () => {
    setShowModal(!showModal);
  };

  const goToDepositFiat = () => {
    if (props.showGuideModal(2)) {
      router.push({
        pathname: WALLET_PATHS.WALLET_DEPOSIT_OTC
      });
      // getBankAccount().then((res) => {
      //   if (res?.code == "0") {
      //     if (res?.data?.status == "active") {
      //       router.push({
      //         pathname: WALLET_PATHS.WALLET_DEPOSIT_USDC,
      //         query: {
      //           accountNumber: res?.data?.accountNumber
      //         }
      //       });
      //     } else {
      //       showCircleModal();
      //       getCircleInfo(res?.data);
      //     }
      //   } else if (res?.code == 6001) {
      //     //未绑定
      //     router.push(WALLET_PATHS.WALLET_ADDACCOUNT);
      //   } else {
      //     let msg = intl?.[res?.msg];
      //     if (!msg) {
      //       msg = intl?.[res?.code] ?? intl?.["global.exception"];
      //     }
      //     message.error(msg);
      //   }
      // });
    }
    reportEvent({ moduleName: "depositFait" });
  };

  return (
    <section className={`${styles.wallet} ` + `customer`}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <div className={styles.topTitleArea}>
              <div className={styles.leftTitleArea}>
                <span className="title">{title}</span>
                <div
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    reportEvent({
                      moduleName: isShowAmount
                        ? GA_EVENT_NAME.wallet.hideAssets
                        : GA_EVENT_NAME.wallet.showAssets
                    });
                    dispatch(updateIsShowAmount(!isShowAmount));
                  }}>
                  {isShowAmount ? (
                    <EyeOutlined className={styles.hideIcon} />
                  ) : (
                    <EyeInvisibleOutlined className={styles.hideIcon} />
                  )}
                </div>
              </div>
              {typeof window != "undefined" ? (
                USER_ROLE_POWER[localStorage.getItem(USER_AUTH) ?? ""]?.depositAndWithdraw ? (
                  <div className={styles.rightTitleArea}>
                    <Button
                      className={styles.whitelistBtn}
                      onClick={() => {
                        router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_MANAGEMENT);
                      }}>
                      {whitelist}
                    </Button>

                    <Button
                      type="primary"
                      className={styles.allBtn}
                      onClick={() => {
                        router.push(WALLET_PATHS.WALLET_DEPOSIT);
                      }}>
                      {deposit}
                    </Button>
                    <Button type="primary" className={styles.allBtn} onClick={goToDepositFiat}>
                      {depositFiat}
                    </Button>
                  </div>
                ) : null
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <div className="section">
        <div className={styles.wallet}>
          {/* @ts-ignore */}
          <Dashboard showGuideModal={props.showGuideModal} />
          <WalletContext.Provider
            value={{ fetchWalletInfo: mutate, showCircleModal, getCircleInfo }}>
            <WalletCategoryDetails />
          </WalletContext.Provider>
          <ModalBankAccountStatus
            showModal={showModal}
            circleInfo={circleInfo}
            showCircleModal={showCircleModal}
          />
        </div>
      </div>
    </section>
  );
}

export const PageWallet = WithKYCInfo(Wallet);
